<template>
  <b-form v-if="deviceData != null" @submit.stop.prevent="onSubmit">
    <b-form-group label="Идентификатор">
      <b-form-input v-model="$v.deviceData.id.$model" required="required"
                    aria-describedby="hexadecimal-validation"
                    :state="$v.deviceData.id.$dirty ? !$v.deviceData.id.$error : null"
                    :readonly="!isAdmin || !creating">
      </b-form-input>
      <b-form-invalid-feedback id="hexadecimal-validation">
        Число должно быть в шестнадцатеричном виде: начинается с символов '0x' и продолжается буквами от 'A' до 'F'
        и/или цифрами
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Название">
      <b-form-input v-model="$v.deviceData.name.$model" required="required"
                    :state="$v.deviceData.name.$dirty ? !$v.deviceData.name.$error : null"
                    :readonly="!isAdmin">
      </b-form-input>
    </b-form-group>
    <b-form-group label="Описание">
      <b-form-textarea v-model="deviceData.description"
                       :readonly="!isAdmin">
      </b-form-textarea>
    </b-form-group>
    <b-button variant="primary" class="w-100 mt-3" type="submit" v-if="isAdmin" :disabled="submitting">
      Сохранить
    </b-button>
    <b-button class="w-100 mt-3" v-bind:to="'/'">
      Назад
    </b-button>
    <b-alert class="mt-2" variant="info" :show="submitting">
      Создание...
    </b-alert>
  </b-form>
  <div v-else>
  </div>
</template>
<script>
import deviceApi from '@/modules/inventory';
import {mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";

export default {
  mounted() {
    if (this.$route.params.id != null) {
      this.getDevice(this.$route.params.id);
    } else {
      this.creating = true;
    }
  },
  data() {
    return {
      creating: false,
      submitting: false,
      deviceData: {
        id: '0x',
        name: null,
        description: null
      },
    };
  },
  validations() {
    return {
      deviceData: {
        id: {
          pattern: (value) => /^0x[a-fA-F\d]+$/.test(value)
        },
        name: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      "isAdmin"
    ])
  },
  methods: {
    onSubmit() {
      this.$v.deviceData.$touch();
      if (this.submitting || this.$v.deviceData.$anyError) {
        return;
      }
      this.submitting = true;
      const requestBody = {
        ...this.deviceData
      };
      requestBody.id = Number(requestBody.id).toString(10);
      this.handleSaveResponse(this.creating ?
          deviceApi.createDevice(requestBody) :
          deviceApi.updateDevice(requestBody));
    },
    handleSaveResponse(axiosCall) {
      axiosCall
          .then(() => {
            this.$router.push('/');
          })
          .catch((e) => {
            if (e.isAxiosError) {
              this.$bvToast.toast(e.response?.status === 403 ?
                  'Недостаточно прав на изменение устройств' :
                  e.response?.status === 409 ?
                      'Устройство с таким id уже существует' :
                      'Возникла неизвестная ошибка при сохранении', {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });
            }
            throw e;
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    getDevice(id) {
      return deviceApi.getDevice(id)
          .then(resp => {
            resp.data.id = "0x" + resp.data.id.toString(16);
            this.deviceData = resp.data;
          });
    }
  }
}
</script>
