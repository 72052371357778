import {AXIOS} from './backend-config';

export default {
    getDeviceList(pageSize, pageNumber, sortBy, sortDirection) {
        return AXIOS.get('/devices', {
            params: {
                pageSize,
                pageNumber,
                sortBy,
                sortDirection
            }
        })
    },
    getDevice(id) {
        return AXIOS.get(`/devices/${id}`);
    },
    createDevice(device) {
        return AXIOS.post(`/devices`,
            JSON.stringify(device),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
    },
    deleteDevice(id) {
        return AXIOS.delete(`/devices/${id}`);
    },
    updateDevice(device) {
        return AXIOS.put(`/devices`,
            JSON.stringify(device),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
    }
}
